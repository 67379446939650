import axios from 'axios';
import * as validators from './validators';

const updateUserData =( dispatch, data ) => {
  const {
    username,
    email,
    password,
    passwordMatch,
    isValid,
    dateOfBirth,
    acceptedTerms,
    dateOfBirthYear,
    dateOfBirthMonth,
    dateOfBirthDay
  } = data;
  
  isValid.dateOfBirth = isValid.dateOfBirthYear && isValid.dateOfBirthMonth && isValid.dateOfBirthDay
  
  if( isValid.email && isValid.username && isValid.password && isValid.passwordMatch  && isValid.dateOfBirth ){
    isValid.pageOne = true;
  } else {
    isValid.pageOne = false;
  }
  
  if( isValid.acceptedTerms ){
    isValid.pageTwo = true;
  } else {
    isValid.pageTwo = false;
  }

  return dispatch ({
    type: 'UPDATE_FORM_DATA',
    payload: {
      username:       username,
      email:          email,
      password:       password,
      passwordMatch:  passwordMatch,
      dateOfBirth:    dateOfBirth,
      acceptedTerms:  acceptedTerms,
      isValid:         isValid,
      dateOfBirthYear:      dateOfBirthYear,
      dateOfBirthMonth:     dateOfBirthMonth,
      dateOfBirthDay:       dateOfBirthDay
    }
  });
  
}

export const handleChange = ( context, event, { name, value } ) => {

  const [ state, dispatch ] = context;
  
  const target = event.target;
  
  value = target.type === 'checkbox' ? target.checked : value;
  
  const date = state.dateOfBirthYear + '-' + state.dateOfBirthMonth + '-' + state.dateOfBirthDay;
  
  const data = Object.assign({}, state, {
    [ name ]:         value,
    dateOfBirth:      date,
  });
  
  data.isValid = Object.assign({}, data.isValid, {
    [ name ]:         validators[ name ]( value, state.password ),
  });
  
  updateUserData(dispatch, data)
}

export const decrementPage = ( context ) => {
  const [ state, dispatch ] = context;
  const { pageNumber } = state;
  if( pageNumber <= 1 ) return;
  return dispatch ({
    type:   'CHANGE_SIGNUP_PAGE_NUMBER',
    payload:{
      pageNumber:  pageNumber - 1
    }
  });
}
  
export const incrementPage = ( context ) => {
  const [ state, dispatch ] = context;
  const { pageNumber, isValid, maxPageNumber } = state;

  if( pageNumber === maxPageNumber ) return;
  
  if( pageNumber < maxPageNumber ){
    return dispatch ({
      type:   'CHANGE_SIGNUP_PAGE_NUMBER',
      payload:{
        pageNumber:  pageNumber + 1
      }
    });
  }
}
  
export const showWaitingScreen = ( dispatch ) => {
  return dispatch ({
    type: 'SHOW_WAITING_SCREEN',
    payload:{
      showWaitingScreen:    true
    }
  });
}
  
export const hideWaitingScreen = ( dispatch ) => {
  return dispatch ({
    type: 'SHOW_WAITING_SCREEN',
    payload:{
      showWaitingScreen:    false
    }
  });
}

export const showSignupError = ( dispatch ) => {
  return dispatch ({
    type: 'SHOW_WAITING_SCREEN',
    payload:{
      signupError:    true
    }
  });
}

export const submitSignup = ( context ) => {
  const [ state, dispatch ] = context;
  
  const { 
    username,
    email,
    password,
    dateOfBirth,
    acceptedTerms
  } = state;
  
  showWaitingScreen( dispatch );
  
  const data = {
    username:       username,
    email:          email,
    password:       password,
    dateOfBirth:    dateOfBirth,
    acceptedTerms:  acceptedTerms
  }
  
  console.log( data )
  
  axios.post('v1/api/signup', data )
  .then( response => {
    console.log(response)
    const { data } = response;
    if(data == 'SUCCESS') window.location.href = '/home';
    else showSignupError( dispatch );
  })
  .catch( error => {
    showSignupError( dispatch );
  });
}

