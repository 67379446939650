import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';

import LandingPage from './pages/LandingPage.jsx';
import LoginPage from './pages/LoginPage.jsx';
import SignupPage from './pages/SignupPage.jsx';
import HomePage from './pages/HomePage.jsx';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={ LandingPage } />
      <Route exact path="/login" component={ LoginPage } />
      <Route exact path="/signup" component={ SignupPage } />
      <Route exact path="/home" component={ HomePage } />
    </Switch>
  );
}

export default App;
