import React, { useContext } from 'react';

import { Link } from 'react-router-dom';


import { Icon } from 'semantic-ui-react';

import SVGIcon from '../SVGIcon.jsx';

import '../../css/SignupPage.css';

import SignupPageContext from '../../context/SignupPageContext.js';

import * as actions from '../../actions/SignupPageActions.js';


const NextButton = () => {
  const context =  useContext( SignupPageContext );
  const [ { pageNumber, isValid } , dispatch ] = context;
  
  if(pageNumber === 1){
    return isValid.pageOne ?
      <div className="SideButton SideButtonPrimary" onClick={ actions.incrementPage.bind( this, context ) } > Next </div>
    :
      <div className="SideButton SideButtonDisabled" > Next </div>
  } else if(pageNumber === 2){
    return isValid.pageTwo ?
      <div className="SideButton SideButtonPrimary" onClick={ actions.incrementPage.bind( this, context ) } > Next </div>
    :
      <div className="SideButton SideButtonDisabled" > Next </div>
  }
}

const SignupPageHeader = () => {
  const context =  useContext( SignupPageContext );

  const [{ pageNumber, maxPageNumber }] = context;
  
  return <div className="SignupHeader"> 
    <div className="SignupHeaderBackContainer" >
      { pageNumber > 1 ? <div onClick={ actions.decrementPage.bind( this, context ) }> <Icon name="arrow left" /> </div> : <div></div>} 
    </div>
    <div className="SignupHeaderLabelContainer">
      {
        pageNumber === maxPageNumber ?
        <div>Finalize</div>
        :
        <div>Step { pageNumber } of 3</div>
      }
      <div className="headerIcon" ><SVGIcon width="256" height="276" /></div>
    </div> 
    <div className="SignupHeaderNextContainer">
      { pageNumber < maxPageNumber ? <NextButton /> : <div></div> }
    </div>
  </div>
}



export default SignupPageHeader;