import React from 'react';

import { Icon }from 'semantic-ui-react';

import * as properties  from '../../properties/HomePageProperties';

const NavigationItems = (props) => {
  const { items } = props;
  return items.map( ( value, key ) => {
    const { url, label, iconName } = value;
    return <a key={ key } href={ url } className="NavigationItemContainer">
      <div className="NavigationItem" >
        <Icon name={ iconName } className="NavIcon"/> 
        <div className="NavLabel">{ label }</div> 
      </div>
    </a>
  })
}

const Navigation = () => {
  return<div className="Navigation">
    <div className="NavigationContainer">
      <NavigationItems items={ properties.NavigationItems } />
    </div>
  </div>
}

export default Navigation;