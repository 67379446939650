import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import SVGIcon from '../components/SVGIcon.jsx';
import '../css/LoginPage.css';

const handleSubmit = ( event ) => {
  event.preventDefault();
  console.log(event.target[0].value)
  if(event.target[0].value === "" && event.target[1].value === "") {
    window.location = "login"
  }
  //attempt login server side
}

const LoginPage = () => {
  return<div className="LoginPage" >
    <div className="dogContainer1" >
      <SVGIcon width="256" height="276" />
    </div>
    <div className="dogContainer2top">
      <div className="dogContainer2" >
        <SVGIcon width="256" height="276" />
      </div>
    </div>
    <div className="LoginFormContainer">
      <Form onSubmit={ handleSubmit } className="LoginForm" >
        <Form.Input placeholder="Email or Username" name="username" />
        <Form.Field> 
          <Form.Input placeholder="Password" name="password" type="password" />
          <div className="optionsBlock">
            <a href="#">Forgot password?</a>
            <a href="/signup">Sign up!</a>
          </div>
        </Form.Field>
        <Form.Field > 
          <Button type="submit" className="ui circular inverted orange">Log In</Button>
        </Form.Field > 
      </Form>
    </div>
  </div>
}

export default LoginPage;