import React, { Component } from 'react';

import axios from 'axios';

import Navigation from '../components/HomePage/Navigation.jsx';
import Main from '../components/HomePage/Main.jsx';

import '../css/HomePage.css';

class HomePage extends Component {
  constructor(){
    super();
    this.state = {
      window: {
        width:    window.innerWidth,
        height:   window.innerHeight
      },
      user: null,
      isAuthenticated: false,
    }
  }
  
  
  
  componentDidMount(){
    axios({
      method:     'get',
      url:        '/api/getAuth'
    })
    .then( response => {
      this.setState({
        user:   response.data.user,
        isAuthenticated: response.data.isAuthenticated
      });
    })
    .catch( error => console.log(error) );
  }
  
  render(){
    return <div className="HomePage">
      <div className="left">
        <Navigation />
      </div>
      <div className="main">
        <Main />
      </div>
    </div>
  }
  
}

export default HomePage;