import React, { 
  Component,
  useReducer,
  useContext
} from 'react';

import axios from 'axios';

import { Modal } from 'semantic-ui-react';

import '../css/SignupPage.css';

import bcrypt from 'bcryptjs';

import SignupContext, { 
  SignupPageProvider,
  signupPageReducer,
  initialSignupState
  
} from '../context/SignupPageContext.js';

import SignupPageHeader from '../components/SignupPage/SignupPageHeader.jsx'
import SignupPageBody from '../components/SignupPage/SignupPageBody.jsx';
import WaitingScreen from '../components/WaitingScreen.jsx';


const SignupPage = () => {

  const [ state, dispatch ] = useReducer( signupPageReducer, initialSignupState );
  
  return <Modal 
    defaultOpen={ true } 
    centered={ true }
    closeOnDimmerClick={ false }
    closeOnDocumentClick= { false }
    size={ 'small' }
  >
    <SignupPageProvider value={ [state, dispatch] }>
     
          <div className="SignupPageFormContainer" >
           
            <SignupPageHeader />
            <div className="SignupPageFormBody">
                  {
                    state.showWaitingScreen ? 
                    <WaitingScreen message={ state.signupError ? state.waitingScreenErrorMessage : state.waitingScreenMessage } />
                  :
                    <SignupPageBody />
                  }
                </div>
            
          </div>
    </SignupPageProvider>
  </Modal>
  
}

export default SignupPage;