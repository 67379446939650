import React, { Component } from 'react';
import '../css/LandingPage.css';
import * as propertiesFile from '../properties/LandingPageProperties.js';


import LandingPageCommunicationBlock from '../components/LandingPage/LandingPageCommunicationBlock.jsx';
import LandingPageUtilityBlock from '../components/LandingPage/LandingPageUtilityBlock.jsx';
import PageFooter from '../components/PageFooter.jsx';


export const LandingPageContext = React.createContext({
	properties: propertiesFile
});

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: false,
		}
	}

	checkLoginStatus = () => {
		let isLoggedIn = false;
		// check server for login status
		
		if(isLoggedIn) {
			//do redirect to home 
		}
	}
	
	componentDidMount(){
		this.checkLoginStatus();
	}
	
	render(){
	
		return <div className="LandingPage">
			<LandingPageContext.Provider value={ propertiesFile }>
				<div className="LandingPageContent">
					<LandingPageUtilityBlock />
					<LandingPageCommunicationBlock />
				</div>
			</LandingPageContext.Provider>
			<PageFooter footerItems={ propertiesFile.FooterItems }/>
		</div>
	}
}

export default LandingPage;