import React from 'react';
import { Icon } from 'semantic-ui-react';

import SVGIcon from '../SVGIcon.jsx';

import { LandingPageContext } from '../../pages/LandingPage.jsx';

const LandingPageCommunicationItem = (props) => {
	return <div className="LandingPage-CommunicationItem" >
		<Icon className="CommIcon" name={ props.iconName } size={ props.size } /> { " " + props.displayText }
	</div>
}

const LandingPageCommunicationContent = ( props ) => {
	const { items } = props;
	return items.map( ( item, key ) => {
		return <LandingPageCommunicationItem 
				key={ key }
				iconName={ item.iconName } 
				displayText={ item.displayText } 
				size={ item.size } 
		/>
	})
}

const LandingPageCommunicationBlock = ( props ) => {
	
	return <LandingPageContext.Consumer>
		{ ( properties ) => {
			return <div className="LandingPageCommunicationBlock LandingPageContentCell">
				<SVGIcon width={ 254 } height={ 274 } />
				<div className="LandingPage-CommunicationContent" >
					<LandingPageCommunicationContent items={ properties.communicationSectionItems } />
				</div>
			</div>
		}}
	</LandingPageContext.Consumer>;
}

export default LandingPageCommunicationBlock;
