export const getYears = () => {
  const yearArr = [];
  for(let i = (new Date()).getFullYear() - 12; i >= 1880; i--){
    yearArr.push({
      key: i,
      text: i,
      value:i
    });
  }
  
  return yearArr;
}


export const getMonths = () => {
  return [
    { key: '1',  text: 'January', value: '01'}, 
    { key: '2',  text: 'February', value: '02'}, 
    { key: '3',  text: 'March', value: '03'}, 
    { key: '4',  text: 'April', value: '04'}, 
    { key: '5',  text: 'May', value: '05'}, 
    { key: '6',  text: 'June', value: '06'}, 
    { key: '7',  text: 'July', value: '07'}, 
    { key: '8',  text: 'August', value: '08'}, 
    { key: '9',  text: 'September', value: '09'}, 
    { key: '10',  text: 'October', value: '10'}, 
    { key: '11',  text: 'November', value: '11'}, 
    { key: '12',  text: 'December', value: '12'}, 
  ];
}

export const getDays = ( month ) => {
  const monthArr = [];
  
  for(let i = 1; i <= 31; i++){
    monthArr.push({
      key: i,
      text: i,
      value:  i < 10 ? '0' + i : i
    });
  }
  
  return monthArr;
}