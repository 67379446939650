import React from 'react';
import { Button, Form} from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';

import SVGIcon from '../SVGIcon.jsx';

const handleSubmit = ( event ) => {
	event.preventDefault();
	if(event.target[0].value === "" && event.target[1].value === "") {
		window.location = "login"
	}
	//attempt login server side
}

const handleLoginClicked = ( event ) => {
	event.preventDefault();
	document.forms['login-form'].submit();
	console.log(document.forms['login-form'])
}


const LandingPageLogin = () => {
	return <div className="LandingPageLogin" >
		<Form onSubmit={ handleSubmit } className="LoginForm" name="login-form" encType="x-www-urlencoded" >
			<Form.Group>
				<Form.Input placeholder="Email or Username" name="username" />
				<Form.Field> 
					<Form.Input placeholder="Password" name="password" type="password" />
					<Link href="#">Forgot password?</Link>
				</Form.Field>
				<Form.Field > 
					<input type="submit" className="SideButton SideButtonSecondary LandingPageLoginButton" value="Log In" />
				</Form.Field > 
			</Form.Group>
		</Form>
	</div>
}

const LandingPageSignUpHeader = () => {
	return <div className="LandingPageSignUpHeader" >
		<div className="TinyIcon" >
			<SVGIcon width={ 254 } height={ 274 } />
		</div>
		<div className="LandingPageSideButtton" >
			<Button className="ui circular inverted orange">Log In</Button>
		</div>
		
	</div>
}

const LandingPageSignUpBlock = () => {
	return <div className="LandingPageSignUpBlock" >
		<LandingPageSignUpHeader />
		<h1 className="LandingPageSignUpTitle">Imagine such wow</h1>
		<div className="LandingPageSelectionArea" >
			<h2 className="LandingPageSelectionAreaSubtitle">Join us</h2>
			<div className="LandingPageButtons" >
				<Link className="SideButton SideButtonPrimary LandingPageSignUpButton" to="/signup"> Sign up </Link>
				<Link className="SideButton SideButtonSecondary LandingPageLoginButton" to="/login" >Log in</Link>
			</div>
		</div>
	</div>
}

const LandingPageUtilityBlock = (props) => {
	return <div className="UtilityBlock  LandingPageContentCell">
		<LandingPageLogin />
		<LandingPageSignUpBlock />
	</div>;
}

export default LandingPageUtilityBlock;