import React, { useContext } from 'react';
import { 
  Form, 
  Input, 
  Checkbox,
  Select
} from 'semantic-ui-react';
import '../../css/SignupPage.css';

import SignupContext from '../../context/SignupPageContext.js';

import * as properties from '../../properties/SignupPageProperties.js';
import * as actions from '../../actions/SignupPageActions.js';
import * as dateOfBirthOptions from './dateOfBirthOptions';

const FinalizeInput = () => {
  const context = useContext( SignupContext );
  
  const [ state ] = context;

  const date = new Date(state.dateOfBirth);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  
  return <div className="FinalFormContainer">
    <div className="FinalFormItem">Create an account with these options?</div>
    <div className="FinalFormItem">{ state.username }</div>
    <div className="FinalFormItem">{ state.email }</div>
    <div className="FinalFormItem">{ month + ' ' + day + ', ' + year }</div>
    <div className="SideButton SideButtonPrimary" onClick={ actions.submitSignup.bind(this, context) } > Sign Up </div>
  </div>

}

const TermsAndConditions = () => {
  const context = useContext( SignupContext );

  const [{ isValid }] = context;
  
  const { errorMessages }  = properties;
  
  return <Form className="SignupPageForm"> 
    <div className="SignupPageFormTitle">
      Accept Terms and Conditions
    </div>
    <Form.Field 
      id="form-input-terms" 
      label="I accept the terms and conditions."
      control={ Checkbox }
      checked={ isValid.acceptedTerms }
      placeholder="Accept Terms" 
      name="acceptedTerms" 
      onChange={ actions.handleChange.bind( this, context ) } 
      error={ !isValid.acceptedTerms ? errorMessages.acceptedTerms : false }
    />
  </Form>
}

const UserInfoInput = () => {
  const context = useContext( SignupContext );
  
  const [ state ] = context;
  
  const { 
    isValid,
    username,
    email,
    password,
    passwordMatch,
    dateOfBirth
  } = state;
  
  const { errorMessages }  = properties;

  return <Form className="SignupPageForm"> 
    <div className="SignupPageFormTitle">
      Create Your Account
    </div>
    <Form.Field 
      id="form-input-username" 
      control={ Input }
      placeholder="Username" 
      name="username" 
      onChange={ actions.handleChange.bind( this, context ) } 
      error={ !(username === '') && ( !isValid.username ? errorMessages.username : false )}
    />
    
    <Form.Field 
      id="form-input-email" 
      control={ Input }
      placeholder="Email" 
      name="email" 
      onChange={ actions.handleChange.bind( this, context ) } 
      error={ !(email === '') && ( !isValid.email ? errorMessages.email : false )}
    />
    
    <Form.Field 
      id="form-input-password" 
      control={ Input }
      placeholder="Password" 
      name="password" 
      type="password" 
      onChange={ actions.handleChange.bind( this, context ) } 
      error={ !(password === '') && ( !isValid.password ? errorMessages.password : false ) }
    />
    
    <Form.Field 
      id="form-input-password-match" 
      control={ Input }
      placeholder="Confirm Password" 
      name="passwordMatch" 
      type="password" 
      onChange={ actions.handleChange.bind( this, context ) }  
      error={ !(passwordMatch === '') && (!isValid.passwordMatch ? errorMessages.passwordMatch : false ) }
    />
    
    <div className="dateOfBirthTitleGroup">
      <div className="dateOfBirthTitle"> Date of Birth </div>
      <div className="dateOfCaution">&nbsp;&nbsp;(Not publicly visible) </div>
    </div>
    
    <Form.Group>
      <Form.Field
        control={ Select }
        options={ dateOfBirthOptions.getYears() }
        onChange={ actions.handleChange.bind( this, context ) }  
        name="dateOfBirthYear"
        placeholder='Year'
        width={4}
        fluid
      />
      
      <Form.Field
        control={ Select }
        options={ dateOfBirthOptions.getMonths() }
        onChange={ actions.handleChange.bind( this, context ) }  
        name="dateOfBirthMonth"
        placeholder='Month'
        width={8}
        fluid
      />
      
      <Form.Field
        control={ Select }
        options={ dateOfBirthOptions.getDays() }
        onChange={ actions.handleChange.bind( this, context ) }  
        name="dateOfBirthDay"
        placeholder='Day'
        width={4}
        fluid
      />
    </Form.Group>
    
    <div className={ dateOfBirth !== "" ?  ( isValid.dateOfBirth ? "displayNone" : "field" ) : "displayNone"}>
      <div className="ui prompt label">{ errorMessages.dateOfBirth.content }</div>
    </div>
    
  </Form>
  
}

const FormBodyContainer = () => {

  const context = useContext( SignupContext );

  const [{ pageNumber }] = context;
  
  switch( pageNumber ) {
    case 1:
      return <UserInfoInput />;
      
    case 2:
      return <TermsAndConditions />;
      
    case 3:
      return <FinalizeInput />;
      
    default:
      return <UserInfoInput />;
  }
  
}

const SignupPageBody = () => {
  return  <FormBodyContainer />
}

export default SignupPageBody;