const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const username = ( name ) => {
  return (name.length >= 6);
}

export const email = ( email ) => {
  // https://github.com/manishsaraan/email-validator
  if (!email)
    return false;
    
  if(email.length>254)
    return false;

  const valid = tester.test(email);
  if(!valid)
    return false;

  // Further checking of some things regex can't handle
  const parts = email.split("@");
  if(parts[0].length>64)
    return false;

  const domainParts = parts[1].split(".");
  if(domainParts.some(function(part) { return part.length>63; }))
    return false;

  return true;
}

export const password = ( password ) => {
  if(password.length < 6) return false;
  
  if(password.search(/[0-9]/) === -1) return false;
  
  if(password.search(/[!@#$%^&*()_+\-=`~|[\]{}<>,.:';"?/\\]/) === -1) return false;
  
  if(password.search(/[A-Z]/) === -1) return false;
  
  if(password.search(/[a-z]/) === -1) return false;
  
  
  return true;
}

export const passwordMatch = ( p1, p2 ) => {
  return p1 === p2;
}

export const dateOfBirth = ( date ) => {
  if(date.length < 9) return false;
  
  const currentDate = new Date();
  
  const testDate = new Date( date );
  
  const difference = currentDate.valueOf() - testDate.valueOf();
  
  const years = difference / ( 1000 * 3600 * 24 * 365);

  return years >= 13
}

export const dateOfBirthYear = () => {
  return true;
}

export const dateOfBirthMonth = () => {
  return true;
}

export const dateOfBirthDay = () => {
  return true;
}
export const acceptedTerms = ( value ) => {
  return value;
}