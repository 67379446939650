import React from 'react';
import '../css/PageFooter.css';

const FooterItem = (props) => {
	const { itemName, url } = props.item;
	return  <li key={ props.key } className="FooterItem">
		<a href={ url } rel="noopener">{ itemName }</a>
	</li>;
}

const FooterItemGenerator = (props) => {
	return props.items.map( (value, key) => {
		return <FooterItem item={ value }  key={ key }/>;
	});
}

const PageFooter = (props) => {
		return <div className="Footer FooterFixed">
			<ul className="FooterList uClearFloat">
				<FooterItemGenerator items={ props.footerItems } />
				<li className="FooterItem FooterCopyright">© Current Year Doger</li>
			</ul>
		</div>;
}

export default PageFooter;