export const communicationSectionItems = [
	{
		iconName: 		'search',
		displayText:	'Get looks.',
		size: 				'big'
	},	
	{
		iconName: 		'users',
		displayText:	'Do chat.',
		size: 				'big'
	},	
	{
		iconName: 		'conversation',
		displayText:	'Much social.',
		size: 				'big'
	}
];

export const FooterItems = [
	{
		itemName:	"Link",
		url:		"#"
	},
	{
		itemName:	"NutherLink",
		url:		"#"
	},
	{
		itemName:	"MoreLink",
		url:		"#"
	},
	{
		itemName:	"LinkPlz",
		url:		"#"
	},
	{
		itemName:	"Turmz",
		url:		"#"
	}
];


	


