export const errorMessages = {
	username: {
		content: "Username must contain at least 6 characters",
		pointing: "left"
	},
	email: {
		content: "You must enter a valid email address",
		pointing: "left"
	},
	password: {
		content: "Password must be longer than 6 characters and contain a number, symbol, and uppercase letter",
		pointing: "left"
	},
	passwordMatch: {
		content: "Confimation must match entered password",
		pointing: "left"
	},
	dateOfBirth: {
		content: "Please enter a valid date of birth",
		pointing: "above"
	},
}
