export const NavigationItems = [
  { 
    url:          '#',
    iconName:     'home',
    label:        'Home'
  },
  { 
    url:          '#',
    iconName:     'mail',
    label:        'Messages'
  },
  { 
    url:          '#',
    iconName:     'alarm',
    label:        'Notifications'
  },
  { 
    url:          '#',
    iconName:     'person',
    label:        'Profile'
  },
  
]