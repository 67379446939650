import React, { useContext } from 'react';

import axios from 'axios';

const SignupPageContext = React.createContext([{}, function() {}]);

export const SignupPageProvider = SignupPageContext.Provider;

export const initialSignupState = {
  username:       '',
  email:          '',
  password:       '',
  passwordMatch:  '',
  dateOfBirth:          '',
  dateOfBirthYear:      '',
  dateOfBirthMonth:     '',
  dateOfBirthDay:       '',
  acceptedTerms:  false,
  pageNumber:     1,
  maxPageNumber:  3,
  isValid: {
    username:         false,
    email:            false,
    password:         false,
    passwordMatch:    false,
    dateOfBirth:      false,
    dateOfBirthYear:  false,
    dateOfBirthMonth: false,
    dateOfBirthDay:   false,
    acceptedTerms:    false,
    pageOne:          false,
    pageTWo:          false
  },
  showWaitingScreen:            false,
  waitingScreenMessage:         'Securely creating your profile. Please wait',
  waitingScreenErrorMessage:    'The was an error. Please refresh the page.',
  signupError:                  false
};

export const signupPageReducer = ( state, action ) => {
    switch ( action.type ){
      case 'UPDATE_FORM_DATA':
        return { ...state, ...action.payload };
        
      case 'CHANGE_SIGNUP_PAGE_NUMBER':
        return { ...state, ...action.payload };
        
      case 'Signup Error':
        return { ...state, ...{ signupError: true } };
        
      case 'SHOW_WAITING_SCREEN':
        return { ...state, ...action.payload };
      
      default:
        return state;
    }
};


export default SignupPageContext;