import React from 'react';

import { Icon } from 'semantic-ui-react';

const WaitingScreen = ( props ) => {
  const { message } = props;
  return <div className="WaitingScreen" >
    <div className="WaitingScreenSpinnerContainer" >
      <Icon name="spinner" className="loading big" />
    </div>
    <div className="WaitingScreenMessage" > { message } </div>
  </div>
}

export default WaitingScreen;